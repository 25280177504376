import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("users", ["userProfiles"]),
  },
  methods: {
    /**
     * gets users by ids from arrayOfObjectsWithUserIds and sets the 'userProfilePictures' state
     * @param {arrayOfObjectsWithUserIds} array of objects that contain ids for which the profile pictures must be fetched
     */
    async getUserProfiles(userIds) {
      return new Promise(async (resolve, reject) => {
        const userIdsToBeFetched = [
          ...new Set(
            userIds.filter(
              (userId) => !Object.keys(this.userProfiles).includes(userId)
            )
          ),
        ];
        if (!!userIdsToBeFetched && (userIdsToBeFetched?.length ?? 0) > 0) {
          try {
            const response = await this.$store.dispatch("users/show", {
              id: userIdsToBeFetched,
            });
            const userProfiles = {
              ...this.userProfiles,
            };
            for (const user of response?.data || []) {
              userProfiles[user.id] = user;
            }
            this.$store.commit("users/userProfiles", userProfiles);
            resolve();
          } catch (e) {
            console.error(e);
          }
        } else {
          resolve();
        }
      });
    },
  },
};
